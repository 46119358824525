import './tableSubtotalSection.scss';
import { deepCopy } from '../../../../shared/componentUtils';
import { addCellClasses, formatContent } from '../../tableUtils';

const TableSubtotalSection = ({ id, layout, 
                                rows, sectionRows,
                                startIndex, endIndex, 
                                columns, buildRow }) => {
    const collapserId = id + '-' + startIndex + '-' + endIndex + '-collapser';
    const subtotals = layout.subtotals;
    const formats = subtotals.formats;
    const newLayouts = deepCopy(layout);
    newLayouts.formats = formats;
    if (subtotals.layouts) {newLayouts.layouts = subtotals.layouts};
    const buildSubtitleRow = (subtitle, index) => {
        return <div key={index + '-subtitle'} className={`table-row subtitle`}>
            {columns.map((column, i) => {
                let content = subtitle[column];
                if (content) { content = content + ' (' + sectionRows.length + ')'}
                const cellClasses = addCellClasses(column, layout, content ? content : 'filler', null);
                return <div key={i + startIndex +column} className={`subtitle-cell table-cell ${content ? 'subtitle-label' : ''} ${cellClasses}`} onClick={onSectionClick}>
                    <div className={'subtitle-cell-content'}>{content}</div>
                </div>
            })}
        </div>
    }

    const buildSectionRows = (rows, startIndex) => {
        return rows.map((row, i) => {
            return buildRow(i + startIndex, row, length);
        })
    }

    const buildSubtotalsRow = (index, row) => {
        const fields = subtotals?.fields;
        return <div key={index + '-subtotal'} className='table-row subtotal' onClick={onSectionClick}>
            {columns.map((column, i) => {
                const content = fields?.[column] === 'none' ? '' : formatContent(row[column], column, newLayouts, true);
                const cellClasses = addCellClasses(column, layout, content ? content : 'filler', null);
                return <div key={i + startIndex + '-' + column} className={`table-cell ${cellClasses}`}>
                    <div className={`table-cell-content total`}>
                        {content}
                    </div>
                </div>
            })}
        </div>
    }

    const onSectionClick = () => {
        const collapser = document.getElementById(collapserId)
        const maxHeight = collapser.style.maxHeight;
        const count = endIndex- startIndex;
        collapser.style.transition = count > 12 ? 'all .8s ' : 'all .3s'; // arbitrary line. This is just to give more time to longer lists.
        collapser.offsetHeight; 
        collapser.style.maxHeight = !maxHeight ? `${(count + 1) * 52}px` : ''; // 52 is from $table-row-height, which is based on $size-xl;
    }
    
    return (
        <div key={startIndex + '-subtotal-section'} className={`table-subtotal-container`}>
            {buildSubtitleRow(rows[startIndex].subtitle, startIndex)}
            <div id={collapserId} className={`table-subtotal-collapser`}>
                <div className='table-subtotal-collapser-spacer'/>
                {buildSectionRows(sectionRows, startIndex + 1)}
                <div className='table-subtotal-collapser-spacer'/>
                {buildSubtotalsRow(endIndex, rows[endIndex].subtotals)}
            </div>
        </div>
    )
}

export default TableSubtotalSection;
