import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { locationGameroomLayout as layout, locationGameroomBetLayout as betLayout } from 'layouts/Locations/LocationGameroomLayout';
import { deepCopy, getDefaultDateRange, getAPIDates } from 'components/shared/componentUtils';
import { getFinanceData } from 'shared/utils';
import { text as t } from 'shared/text';
import * as e from 'shared/excel';
import useProfile from 'hooks/useProfile';
import useTables from 'components/elements/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import Button from 'components/elements/Button/Button';
import Card from 'OldComponents/Cards/Card/Card';
import CashDisplay from 'components/elements/CashDisplay/CashDisplay';
import DateRangePicker from 'components/elements/DateRangePicker/DateRangePicker';
import FinanceDisplay from 'components/elements/FinanceDisplay/FinanceDisplay';
import TableCheckbox from 'components/elements/Tables/TableParts/TableCheckbox/TableCheckbox';
import TableListed from 'components/elements/Tables/TableListed';

const LocationGameroom = () => {
    const { id } = useParams();
    const { updateLocationBreadcrumbs, updateLocationHeader } = useProfile();
    const { tableData, tableTotals, tableToggles,
            getTableColumns, updateTable } = useTables();
    const { callAPI, callAPIGet } = useUtils();
    const [ pageData, setPageData ] = useState();
    const [ tableRecords, setTableRecords ] = useState();
    const [ currentLayout, setCurrentLayout ] = useState(layout);
    const [ masked, setMasked ] = useState([true]);
    const [ currentRange, setCurrentRange ] = useState();
    const [ financials, setFinancials ] = useState();
    const [ cashBreakdown, setCashBreakdown ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const deviceTypes = useRef();
    const toggleTimeout = useRef();
    const tableId = 'location-gameroom-' + id;
    const toggleProp = 'betLevel';

    useEffect(() => {
        callAPI('locationDetails', locationDetailsCallback, {id: id});
    }, []);
    
    useEffect(() => {
        const showBetRates = tableToggles?.[tableId]?.includes(toggleProp) ? true : false;
        getReportData(currentRange ? currentRange : getDefaultDateRange(tableId), showBetRates);
        clearTimeout(toggleTimeout.current);
        toggleTimeout.current = setTimeout(()=> {
            setCurrentLayout(showBetRates ? betLayout : layout)
        }, 300);
    }, [tableToggles?.[tableId]])

    const locationDetailsCallback = (data) => {
        const details = data?.locationDetails;
        deviceTypes.current = data?.kioskTypes;
        setPageData(details);
        updateLocationHeader(details);
        updateLocationBreadcrumbs(details);
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        setCurrentRange(range);
    }

    const getReportData = (range, showBetRates) => {
        setMasked([true]);
        tableId && updateTable('dates', tableId, range);
        const params = getAPIDates(range);
        params.locationId = id;
        params.betLevel = showBetRates;
        callAPIGet('report-LocationGameroom', gameroomReportCallback, params);
    }

    const gameroomReportCallback = (data) => {
        const records = data?.data ? data.data : [];
        setTableRecords(records);
        const fin = getFinanceData(deviceTypes.current, data?.totalFinancialSummary, records)
        setFinancials(fin);
        setCashBreakdown(data?.totalCashBreakdown ? data.totalCashBreakdown : null);
        setMasked([false]);
    }

    const onDateRangePick = (range) => {
        getReportData(range);
        setCurrentRange(range);
    }

    const onExportToExcel = () => {
        const dateInfo = {
            type: 'table',
            columns: ['locationId', 'locationName', 'startDate', 'endDate', ],
            headers: {
                locationId: t.locationId,
                locationName: t.locationName,
                startDate: t.startDate,
                endDate: t.endDate,
            },
            data:[{
                locationId: id,
                locationName: pageData.locationName,
                startDate: e.getExcelDate(currentRange[0]),
                endDate: e.getExcelDate(currentRange[1]),
            }],
            configs: {
                formats: {locationName: 'text-wrap'}
            }
        };

        const financeInfo = e.getExcelFinancials(financials);

        const cashInfo = cashBreakdown ? e.getExcelCash(cashBreakdown) : null;

        const tableRowData = deepCopy(tableData[tableId]);
        if (currentLayout.subtotals){
            tableRowData.push({totals: tableTotals[tableId]});
        }
        const columns = getTableColumns(tableId, currentLayout);
        const tableInfo = e.getExcelTable( t.gameroomPerformance, columns, tableRowData, currentLayout);
        const infoArray = cashBreakdown ? [dateInfo, financeInfo, cashInfo, tableInfo] : [dateInfo, financeInfo, tableInfo]
        const sizes = columns.map(key => currentLayout.sizes[key]);
        e.exportToFile(id + ' Gameroom Performance Report', infoArray, sizes)
    }

    return (<div className='full grid'>
        <Card hideLabel={true} classes='full'>
            <DateRangePicker
                classes='quarter'
                defaultVal={defaultDates}
                label={t.dateRange}
                disabled={masked?.[0]}
                callback={onDateRangePick}
            />
            <Button 
                classes='green sixth match-labeled-input'
                disabled={masked?.[0]}
                onClick={onExportToExcel}
            >{t.exportToExcel}</Button>
        </Card>
        <Card label={t.financials} classes='third' masked={masked?.[0]}> 
            <FinanceDisplay types={deviceTypes.current} classes='full' data={financials?.data} hideTitle={true} tooltip={true}/>
        </Card>
        {cashBreakdown && <Card label={t.cashBreakdown} classes='third' masked={masked?.[0]}>
            <CashDisplay classes='full' data={cashBreakdown} />
        </Card>} 
        <div className='table-title full'>{t.gameDetails}</div>
        <TableListed
            id={tableId}
            classes='full'
            data={tableRecords}
            layout={currentLayout}
            masked={masked?.[0]}
            searchBarContent={<>
                <TableCheckbox
                    id={toggleProp}
                    tableId={tableId}
                    tableState='toggles'
                    classes='table-searchbar sixth'
                    prop={toggleProp}
                    label={t.showBetRates}
                    masked={masked?.[0]}
                />
            </>}
        />
    </div>)
}

export default LocationGameroom;
