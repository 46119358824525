import './page.scss';
import { useEffect, useRef } from 'react';
import useInputs from 'components/hooks/Inputs/useInputs';
import useProfile from 'hooks/useProfile';
import useUtils from 'hooks/useUtils';
import Tab from 'OldComponents/Tab/Tab';

const Page = ({page, subPage, tabs, classes, contentClasses, children}) => {
    const { clearAllInputs } = useInputs();
    const { setHeader } = useProfile();
    const { hasRank } = useUtils();
    const runOnce = useRef(false);

    useEffect(()=>{
        if (runOnce.current) {return}
        runOnce.current = true;
        page && setHeader(page);
        clearAllInputs();
    },[])

    useEffect(()=>{
        window.scrollTo(0,0)
    }, [window.location.href])

    return (
        <div className={`page ${classes? classes : ''} ${subPage ? 'has-tabs' : ''}`}>
            {subPage && <div className='page-header'>
                <div className='tab-bar'>
                    {tabs && tabs.map((props, i) => {
                        if (!hasRank(props?.rank)) {return}
                        return <Tab
                            key={i}
                            data={props}
                            selected={props.label === subPage? true : false}
                        />
                    })}
                </div>
            </div>}
            <div className={`page-content ${subPage ? 'has-header' : ''} ${contentClasses ? contentClasses : ''}`}>
                {children}
            </div>
        </div>
    )
}

export default Page;
