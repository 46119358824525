import 'modals/FlowModals/DeviceActionModal/deviceActionModal.scss';
import { getActionType } from 'shared/utils';
import { useState } from 'react';
import { text as t } from 'shared/text';
import useModal from 'components/hooks/Modal/useModal';
import useProfile from 'hooks/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/elements/Button/Button';
import Input from 'components/elements/Input/Input';
import Modal from 'components/elements/Modal/Modal';
import MultiCheckModal from 'OldComponents/ContextMenu/MultiCheckModal/MultiCheckModal';

const DeviceActionModal = ({pageId, prop, terminalId}) => {
    const { setModal } = useModal();
    const { tableStates } = useProfile();
    const [ error, setError ] = useState('');
    const { callAPI } = useUtils();
    const commandInputId = 'device-command-input'

    const onActionConfirm = (event, setLoading) => {
        const states = tableStates[pageId];
        const multiCheck = states?.multiCheck;
        const command =  prop === 'executeScript' ? document.getElementById(commandInputId).value: null;
        if(!terminalId && multiCheck && multiCheck.length >1){
            setModal(<MultiCheckModal pageId={pageId} prop={prop} type={getActionType(prop)} data={command}/>);
            return
        }
        const params = {
            terminalId: terminalId ? terminalId : multiCheck[0],
            terminalCommandType: getActionType(prop)
        };
        if(command){
            params['data'] = command;
        }
        const props = {
            event: event,
            setLoading: setLoading
        }
        setLoading(true);
        callAPI('terminalPost', terminalActionCallback, params, '', props)
    }

    const terminalActionCallback = (data, props) => {
        props.setLoading(false);
        if (!data.isSuccessful) {
            setError(data.errorMessage);
            return
        }
        setModal(false);
    }

    const getMessage = () => {
      if(prop === 'executeScript')  return t.executeScriptConfirmMessage;
      if(prop === 'openSupportTunnel')  return t.openSupportTunnelConfirmMessage;

    }

    const onCancel = () => {
        setModal(false);
    }

    return (
        <Modal title={t.confirm} classes={'device-action-modal'}>
            <div className='action-message'>{getMessage()}</div>
            {error !== ''? <div className='action-error-message'>{getMessage()}</div> :''}
            { prop === 'executeScript' ?  <Input id={commandInputId} classes="full" defaultVal={''} maxLength={400}/>: ''}
            <div className='device-action-modal-button-container'>
                <Button classes='green half' type='submit' onClick={(event, setLoading)=>onActionConfirm(event, setLoading)}>{t.sendCommand}</Button>
               <Button classes='red half' onClick={onCancel}>{t.cancel}</Button>
            </div>
        </Modal>
    )
}

export default DeviceActionModal;
