import './tableHeaderCell.scss';
import { useEffect, useState } from 'react';
import { addCellClasses } from '../../../tableUtils';
import Icon from '../../../../Icon/Icon';
import useTable from '../../TableHooks/useTables';

const TableHeaderCell = ({id, layout, label, prop, index, noURL}) => {
    const { tableSorts, tableCheckAlls, updateTable } = useTable();
    const [ currentArrow, setCurrentArrow ] = useState(); 
    useEffect(()=> {
        const sort = tableSorts?.[id];
        setCurrentArrow((sort?.orderBy !== prop) ? '' : (sort?.orderBy === prop && sort?.desc) ? 'arrow-down' :  'arrow-up');
    }, [tableSorts?.[id]])

    const onMultiCheckAll = () => {
        const checks = document.getElementById(id).getElementsByClassName('table-multi-check');
        if (!tableCheckAlls?.[id]) {
            const array = [];
            for (let i=0; i<checks.length; i++) {
                array.push(checks[i].dataset.rowid)
            }
            updateTable('checks', id, array, noURL);
        } else {
            updateTable('checks', id, null, noURL);

        }
        updateTable('checkAlls', id, !tableCheckAlls?.[id], noURL);
    }

    const onSortArrowClick = () =>{
        if (layout?.hideSortArrows?.[prop]) {return}
        const newArrow = nextArrowState();
        const sortData = {
            orderBy: !newArrow ? newArrow : prop,
            desc: newArrow === 'arrow-down' ? true : false,
        }
        updateTable('sorts', id, !newArrow ? null : sortData, noURL);
    }

    const nextArrowState = () => {
        switch (currentArrow) {
            case '':
                return 'arrow-down';
            case 'arrow-down':
                return 'arrow-up';
            default:
                return ''
        }
    }
    return (
        <div key={index} className={`table-header-cell ${addCellClasses(prop, layout, label)}`}>
            {prop === 'checkbox' ?<label className='table-checkbox'>
                <input
                    id={id + 'table-check-all'}
                    className='table-multi-check-all'
                    type='checkbox'
                    checked={tableCheckAlls?.[id] ? true : false}
                    onChange={onMultiCheckAll}
                />
                <span className='checkmark'></span>
            </label>
            : <div className={`table-header-cell-contents ${id}-order-${prop}`} data-prop={prop}>
                <div className={`table-header-cell-text ${layout?.hideSortArrows?.[prop] ? 'no-click' : ''}`} onClick={(event)=>{onSortArrowClick(event)}}>{label}</div>
                {currentArrow && <Icon icon={currentArrow} classes={`table-header-sort-arrow ${layout?.hideSortArrows?.[prop] ? 'hide' : ''}`}/>}
            </div>}
        </div>
    )
}





export default TableHeaderCell;
