import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { deepCopy, updateURL } from '../../../../shared/componentUtils';
import TablesContext from './TablesContext';


const useTables = () => {
    const { tableData, setTableData,
            tableTotals, setTableTotals,
            tableColumnsRemoved, setTableColumnsRemoved,
            tableSearches, setTableSearches,
            tableSorts, setTableSorts,
            tableDropdowns, setTableDropdowns,
            tableDates, setTableDates,
            tableToggles, setTableToggles,
            tableSelectedRows, setTableSelectedRows,
            tableCheckAlls, setTableCheckAlls,
            tableChecks, setTableChecks,
            tableRowCounts, setTableRowCounts,
            tablePageCounts, setTablePageCounts,
            tableCurrentPages, setTableCurrentPages } = useContext(TablesContext);
    const changeTableData = (a) => {setTableData(a)};
    const changeTableTotals = (a) => {setTableTotals(a)};
    const changeTableColumnsRemoved = (a) => {setTableColumnsRemoved(a)};
    const changeTableSearches = (a) => {setTableSearches(a)};
    const changeTableSorts = (a) => {setTableSorts(a)};
    const changeTableDropdowns = (a) => {setTableDropdowns(a)};
    const changeTableDates = (a) => {setTableDates(a)};
    const changeTableToggles = (a) => { setTableToggles(a)};
    const changeTableSelectedRows = (a) => {setTableSelectedRows(a)};
    const changeTableCheckAlls = (a) =>{setTableCheckAlls(a)};
    const changeTableChecks = (a) => {setTableChecks(a)};
    const changeTableRowCounts = (a) => {setTableRowCounts(a)};
    const changeTablePageCounts = (a) => {setTablePageCounts(a)};
    const changeTableCurrentPages = (a) => {setTableCurrentPages(a)};
    const location = useLocation();

    const updateTable = (type, id, val, noURL) => {
        const newStates = getState(type);
        const setState = getSetState(type);
        if (!newStates) { return }    
        if (!newStates[id]) { newStates[id] = {}};
        newStates[id] = val;
        setState(newStates);
        updateTableURL(type, val, id)
    }
    
    const getURLParams = (id) => {
        const params = new URLSearchParams(location.search)
        return params.get(id) ? JSON.parse(params.get(id)) : {};
    }

    const updateTableWithURL = (id, layout) => {
        const searchParams = new URLSearchParams(location.search);
        if (!searchParams.size) { return true; }
        const urlParams = getURLParams(id);
        if (urlParams['searches']) {  
            updateTable('searches', id, urlParams['searches']);  
        }
        if (urlParams['columnsRemoved']) {
            updateTable('columnsRemoved', id, urlParams['columnsRemoved']);  
        }
        if (urlParams['sorts']) {  
            updateTable('sorts', id, urlParams['sorts']);  
        }
        if (urlParams['dropdowns']) {  
            updateTable('dropdowns', id, urlParams['dropdowns']);  
        }
        if (urlParams['toggles']) {
            updateTable('toggles', id, urlParams['toggles']);  
        }
        if (urlParams['rowCounts']) {  
            updateTable('rowCounts', id, urlParams['rowCounts']);  
        }
        if (urlParams['currentPages']) {  
            updateTable('currentPages', id, urlParams['currentPages']);  
        }
        return false;
    }

    const updateTableURL = (type, val, id) => {
        if (type === 'data' || type === 'totals' || type === 'selectedRows') {return};
        updateURL(id, type, val);
    }

    const updateDropdowns = (tableId, fieldId, data) => {
        let newData;
        if (tableDropdowns?.[tableId]) {
            newData = deepCopy(tableDropdowns[tableId]) 
            newData[fieldId] = data;
        } else {
            newData = {[fieldId]: data}
        }
        updateTableURL('dropdowns', newData, tableId);
        changeTableDropdowns({[tableId]: newData});
    }

    const getTableColumns = (id, layout) => {
        if (!layout) {return null};
        const columns = [];
        const headers = Object.keys(layout.tableHeaders);
        const columnsRemoved = tableColumnsRemoved?.[id] ? tableColumnsRemoved[id] : null;
        const layouts = layout.layouts;
        headers.forEach((header) => {
            if (!layouts?.[header]?.includes('hidden') && (!columnsRemoved || !columnsRemoved.includes(header))) {
                columns.push(header);
            }
        })
        return columns;
    }

    const getState = (type) => {
        let state;
        switch (type) {
            case 'data': 
                state = tableData; break;
            case 'totals':
                state = tableTotals; break;
            case 'columnsRemoved':
                state = tableColumnsRemoved; break;
            case 'searches':
                state = tableSearches; break;
            case 'sorts':
                state = tableSorts; break;
            case 'dropdowns':
                state = tableDropdowns; break;
            case 'dates': 
                state = tableDates; break;
            case 'toggles': 
                state = tableToggles; break;
            case 'checkAlls':
                state = tableCheckAlls; break;
            case 'checks':
                state = tableCheckAlls; break;
            case 'selectedRows':
                state = tableSelectedRows; break;
            case 'rowCounts':
                state = tableRowCounts; break;
            case 'pageCounts':
                state = tablePageCounts; break;
            case 'currentPages':
                state = tableCurrentPages; break;
            default: 
                return null;
        }
        return !state ? {} : deepCopy(state);
    }

    const getSetState = (type) => {
        switch (type) {
            case 'data': 
                return changeTableData;
            case 'totals':
                return changeTableTotals;
            case 'columnsRemoved':
                return changeTableColumnsRemoved;
            case 'searches':
                return changeTableSearches;
            case 'sorts':
                return changeTableSorts;
            case 'dropdowns':
                return changeTableDropdowns;
            case 'dates':
                return changeTableDates;
            case 'toggles':
                return changeTableToggles;
            case 'checkAlls':
                return changeTableCheckAlls;
            case 'checks':
                return changeTableChecks;
            case 'selectedRows':
                return changeTableSelectedRows;
            case 'rowCounts':
                return changeTableRowCounts;
            case 'pageCounts':
                return changeTablePageCounts;
            case 'currentPages':
                return changeTableCurrentPages;
            default: 
                return null;
        }
    }

    const clearTableDropdown = (tableId)=> {
        const newData = tableDropdowns?.[tableId] ? deepCopy(tableDropdowns) : {[tableId]:{}};
        newData[tableId] = null;
        changeTableDropdowns(newData);
    }

    const clearTable = (id) => {
        updateTable('data', id, null);
        updateTable('totals', id, null);
        updateTable('columnsRemoved', id, null);
        updateTable('searches', id, null);
        updateTable('sorts', id, null);
        updateTable('dropdowns', id, null);
        updateTable('dates', id, null);
        updateTable('toggles', id, null);
        updateTable('selectedRows', id, null);
        updateTable('checkAlls', id, null);
        updateTable('checks', id, null);
        updateTable('rowCounts', id, null);
        updateTable('pageCounts', id, null);
        updateTable('currentPages', id, null);
    }

    const clearAllTables = () => {
        changeTableData(null);
        changeTableTotals(null);
        changeTableSearches(null);
        changeTableSorts(null);
        changeTableDropdowns(null);
        changeTableDates(null);
        changeTableToggles(null);
        changeTableSelectedRows(null);
        changeTableCheckAlls(null);
        changeTableChecks(null);
        changeTableRowCounts(null);
        changeTablePageCounts(null);
        changeTableCurrentPages(null);
    }

    return { 
        tableData, tableTotals, 
        tableSearches, tableColumnsRemoved, 
        tableSorts, tableDropdowns, 
        tableDates, tableToggles,
        tableCurrentPages, tableCheckAlls, 
        tableChecks, tableSelectedRows, 
        tableRowCounts, tablePageCounts, getState,
        getTableColumns,  getURLParams,
        updateTableWithURL, updateTableURL,
        updateTable, changeTableTotals,
        updateDropdowns, clearTableDropdown,
        clearTable, clearAllTables
    };
}

export default useTables;