import './locationPerformanceReports.scss';
import { useEffect, useRef, useState } from 'react';
import { locationPerformanceReportsLayout as layout } from 'layouts/Reports/LocationPerformanceReportsLayout';
import { getDefaultDateRange, getAPIDates } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import useInputs from 'components/hooks/Inputs/useInputs';
import useTables from 'components/elements/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import DateRangePicker from 'components/elements/DateRangePicker/DateRangePicker';
import Dropdown from 'components/elements/Dropdown/Dropdown';
import TableListed from 'components/elements/Tables/TableListed';
const tableId = 'locationPerformanceReports';
const companyId = tableId + '-companyId';

const LocationPerformanceReports = () => {
    const { updateDropdown } = useInputs();
    const { updateTable, updateDropdowns, getURLParams } = useTables();
    const { callAPI, callAPIGet } = useUtils();
    const [ companies, setCompanies ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ tableData, setTableData ] = useState();
    const [ masked, setMasked ] = useState([true]);
    
    useEffect(() => {
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        getReportData(range);
        callAPI('companies', getCompaniesCallback);
    }, []);

    useEffect(() => {
        const urlParams = getURLParams(tableId);
        if (urlParams.dropdowns) {
            const sorts = urlParams.dropdowns?.[companyId];
            sorts && updateDropdown(companyId, sorts.value, sorts.label);
        }
    }, [companies])

    const getReportData = (range) => {
        setMasked([true]);
        updateTable('dates', tableId, range);
        const params = getAPIDates(range);
        callAPIGet('report-LocationPerformance', locationReportCallback, params);
    }
    
    const locationReportCallback = (data) => {
        setTableData(data?.data ? data.data : []);
        setMasked([false]);
    }

    const getCompaniesCallback = (data) => {
        setCompanies(data?.companyList ? data.companyList : []);
    }

    const onCompanySelect = (data) => {
        data.searchData = {
            dropdownProp: 'label',
            recordProp: 'companyName'
        }
        updateDropdowns(tableId, companyId, data);
    }

    const onDateRangePick = (range) => {
        getReportData(range);
    }

    return (
            <TableListed
                id={tableId}
                data={tableData}
                layout={layout}
                masked={masked?.[0]}
                searchBarContent={<>
                    <Dropdown
                        id={companyId}
                        classes='quarter'
                        label={t.companyName}
                        hideErrors={true}
                        masked={masked?.[0]}
                        data={companies}
                        nullable={t.all}
                        callback={onCompanySelect}
                    />
                    <DateRangePicker
                        id='dateRange'
                        classes='quarter'
                        label={t.dateRange} 
                        disabled={masked?.[0]}
                        defaultVal={defaultDates}  
                        callback={onDateRangePick}
                    />
                </>}
            />
    )
}

export default LocationPerformanceReports;
