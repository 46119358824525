import './permissions.scss';
import { useEffect, useState } from 'react';
import { permissionsLayout as layout } from 'layouts/Permissions/PermissionsLayout';
import { permissionTabsList as tabs } from 'layouts/Permissions/PermissionTabs';
import { isValid } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import useAuth from 'hooks/useAuth';
import useInputs from 'components/hooks/Inputs/useInputs';
import useUtils from 'hooks/useUtils';
import Card from 'OldComponents/Cards/Card/Card';
import Dropdown from 'components/elements/Dropdown/Dropdown';
import Page from 'OldComponents/Page/Page';
const companyId = 'permissions-companyId';
const roleId = 'permissions-roleId';

const Permissions = () => {
    const { getAuth } = useAuth();
    const { inputValues, clearInput, clearAllInputs } = useInputs();
    const { callAPI, getRolesList, hasRank } = useUtils();
    const [ companies, setCompanies ] = useState();
    const [ assignedUsers, setAssignedUsers ] = useState();
    const [ masked, setMasked ] = useState([true]);
    const hasCompanies = hasRank('superuser');

    useEffect(() => {
        !isValid(companies) && callAPI('companies', companiesCallback);
        return () => {clearAllInputs()};
    }, []);

    useEffect(()=>{
        inputValues[companyId] && resetToCompany();
    }, [inputValues[companyId]])

    const companiesCallback = (data) => {
        if (data?.isSuccessful) {
            const list = data?.companyList ? data.companyList : [];
            setCompanies(list);
        }
        setMasked([false])
    }

    const onRoleSelect = (data) => {
        callAPI('assignedUsers', assignedUsersCallback, {
            roleId: data.value, 
            companyId: hasCompanies ? inputValues[companyId] : getAuth().companyId
        }); 
    }

    const assignedUsersCallback = (data, setLoading) => {
        setLoading && setLoading(false);
        if (data.isSuccessful) {
            setAssignedUsers(data.usersAssignedToRole)
        } else {
            setAssignedUsers([]);
        }
    }

    const resetToCompany = () => {
        clearInput(roleId, true);
        setAssignedUsers(null);
    }

    const buildAssignedUsers = () => {
        if (!assignedUsers) { return }
            const length = assignedUsers.length;
        if (length === 0) {
            return <div className='permissions-no-results'>{t.noResults}</div>
        } else {
            return <div className='permissions-user-line-container'>
                {assignedUsers.map((user, i) => {
                    const userId = user.id
                    const id = 'permissions-user-' + userId + '-' + i;
                    return (<div id={id} key={i + '-' + user} className={`permissions-user-line`}>
                        <div className='permissions-user-cell name'>{user.firstName + ' ' + user.lastName}</div>
                        <div className='permissions-user-cell email'>{user.email}</div>
                    </div>)
                })}
            </div>
        }
    }

    return (
        <Page 
            classes='permissions-page'
            page={layout.page}
            subPage={layout.subPage}
            tabs={tabs}
            contentClasses='grid'
        >
            <Card hideLabel={true} classes='half'>
                {hasCompanies && <Dropdown id={companyId} classes='full' masked={masked?.[0]}
                            label={t.company} data={companies}/>}
                <Dropdown   id={roleId} classes='full' masked={masked?.[0]} disabled={hasCompanies && !inputValues?.[companyId]} 
                            label={t.role} data={getRolesList()} labelProp='role' callback={onRoleSelect}/>
            </Card>
            {inputValues[roleId] && <Card hideLabel={true} classes='half'>
                <div className='permissions-card-label full'>{t.usersAssignedRole}</div>
                <div className='full'>
                    <div className={`permissions-user-line permissions-header`}>
                        <div className='permissions-user-cell name permissions-header'>{t.name}</div>
                        <div className='permissions-user-cell email permissions-header'>{t.email}</div>
                    </div>
                    {assignedUsers && buildAssignedUsers()}
                </div>
            </Card>}
        </Page>
   )
}

export default Permissions;
