import './locationReports.scss';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { locationTabsList } from 'layouts/Locations/LocationTabs';
import { text as t } from 'shared/text';
import useComponents from 'components/hooks/Components/useComponents';
import useInputs from 'components/hooks/Inputs/useInputs';
import useProfile from 'hooks/useProfile';
import useUtils from 'hooks/useUtils';
import Card from 'OldComponents/Cards/Card/Card';
import Dropdown from 'components/elements/Dropdown/Dropdown';
import Page from 'OldComponents/Page/Page';
import LocationTransactions from './LocationTransactions/LocationTransactions';
import LocationGameroom from './LocationGameroom/LocationGameroom';
import LocationKiosk from './LocationKiosk/LocationKiosk';
import LocationPlayerInfo from './LocationPlayerInfo/LocationPlayerInfo';

const locationReports = {
    transactions: <LocationTransactions/>,
    kiosk: <LocationKiosk/>,
    gameroom: <LocationGameroom/>,
    playerInfo: <LocationPlayerInfo/>
}

const LocationReports = () => {
    const { id, type } = useParams();
    const { navigateTo } = useComponents();
    const { updateDropdown } = useInputs();
    const { updateLocationHeader } = useProfile();
    const { callAPI } = useUtils();
    const [ reportsList, setReportsList ] = useState();
    const [ currentReport, setCurrentReport ] = useState(type ? type : 'transactions');

    useEffect(() => {
        const params = {id: id}
        callAPI('locationDetails', locationDetailsCallback, params);
        updateDropdown('location-reports', type, getDropdownLabel(type));
        setCurrentReport(type);
    }, [type])

    useEffect(() => {
        if (currentReport && type !== currentReport) {
            navigateTo(`/location/${id}/reports/${currentReport}`);
        }
    }, [currentReport]);

    const locationDetailsCallback = (data) => {
        setReportsList(getReportsList(data?.kioskTypes))
        updateLocationHeader(data?.locationDetails);
    }

    const onReportSelect = (data) => {
        setCurrentReport(data.value)
    }

    const getReportsList = (array) => {
        if (!array || array.length === 0) {return []}
        const hasGRC = array.includes(2);
        const hasPiggyBank = array.includes(3);
        const hasPOS = array.includes(4);
        const reportsList = [{value: 'transactions', label: t.transactions}]
        if (hasPOS) {
            reportsList.push({value: 'gameroom', label: t.gameroom});
        } else if (hasPiggyBank || hasGRC)  {
            reportsList.push({value: 'kiosk', label: t.kiosk});
        }
        if (hasGRC || hasPOS) {reportsList.push({value: 'playerInfo', label: t.playerInfo})}
        return reportsList;
    }

    const getDropdownLabel = (val) => {
        switch(val) {
            case 'gameroom':
                return t.gameroom;
            case 'kiosk':
                return t.kiosk;
            case 'playerInfo':
                return t.playerInfo
            default:
                return t.transactions;
        }
    }

    return (
        <Page
            subPage={t.reports}
            tabs={locationTabsList}
        >
            <Card hideLabel={true} classes='grid margin-bottom'>
                <Dropdown
                    id='location-reports'
                    classes='quarter'
                    label={t.selectedReport}
                    noPermission={reportsList?.length <= 1}
                    hideErrors={true}
                    data={reportsList}
                    callback={onReportSelect}/>
            </Card>
            {locationReports[currentReport]}
        </Page>
    )
}

export default LocationReports;
