import './noticeModal.scss';
import { text as t } from 'shared/text';
import useModal from 'components/hooks/Modal/useModal';
import Button from 'components/elements/Button/Button';
import Modal from 'components/elements/Modal/Modal';

const NoticeModal = ({header, content, onConfirm}) => {
    const { removeModal } = useModal();

    const confirm = () => {
        if(onConfirm) {
            onConfirm();
        }
        removeModal(false);
    }

    return (
        <Modal title={header} classes='notice-modal'>
            <div className='grid'>
                {content && <div className='notice-modal-message five-sixths centered'>{content}</div>}
                <Button classes='green half centered' onClick={confirm}>{t.confirm}</Button>
            </div>
        </Modal>
    )
}

export default NoticeModal;
