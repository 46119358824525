import './tableRowCountPicker.scss';
import { useState } from 'react';
import { toNum } from '../../../../../shared/componentUtils';
import { tablesData as t } from '../../../tablesData';
import useTable from '../../TableHooks/useTables';

const TableRowCountPicker = ({id, layout}) => {
    const { tableRowCounts, updateTable } = useTable();
    const [ displayOpen, setDisplayOpen] = useState(false);
    const [ menuOpen, setMenuOpen ] = useState(false); 
    const [ menuBorder, setMenuBorder ] = useState(false);
    const rowCounts = tableRowCounts?.[id];

    const buildOption = (value, i) => {
        return <div key={i} className='row-count-option' data-value={value} onClick={(event) => onSelect(event.currentTarget)}>{value}</div>
    }

    const onSelect = (target) => {
        updateTable('rowCounts', id, target.innerHTML);
    };

    const toggleDropdown = () => {
        if (!displayOpen) {
            setDisplayOpen(true);
            setTimeout(() => {
                setMenuBorder(true);
                setMenuOpen(true);
            }, 25)
        } else {
            closeDropdown();
        }
    } 

    const onExit = () => {
        closeDropdown();
    }
    
    const closeDropdown = () =>{
        setMenuOpen(false);
        setTimeout(() => {
            setMenuBorder(false);
            setDisplayOpen(false);
        }, 150)
    }

    return (
        <div  className={`row-count-picker ${layout?.subtotals ? 'display' : ''}`} >
            <label className='label-first'>{layout?.subtotals ? t.allNoCap : t.showNoCap}</label>
            <div className={`row-count-container`} onClick={toggleDropdown} onMouseLeave={(event)=>{onExit(event)}}>
                <div className={`row-count-display ${displayOpen ? 'open' : ''}`}>
                    {!rowCounts ? '' : toNum(rowCounts)}
                </div>
                <div className={`row-count-menu ${menuOpen ? 'open' : ''} ${menuBorder ? 'border' : ''}`}> 
                    {t.rowCounts.map((item, i)=>{
                        return buildOption(item, i)
                    })}
                </div>
            </div>
            <label className='label-last'>{layout?.subtotals ? t.recordsShownNoCap: t.recordsNoCap}</label>
        </div>
    )   
};

export default TableRowCountPicker;