import './header.scss';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { text as t } from 'shared/text';
import useComponents from 'components/hooks/Components/useComponents';
import useInputs from 'components/hooks/Inputs/useInputs';
import useModal from 'components/hooks/Modal/useModal';
import useProfile from 'hooks/useProfile';
import useUtils from 'hooks/useUtils';
import useTables from 'components/elements/Tables/TableParts/TableHooks/useTables';
import Button from 'components/elements/Button/Button';
import AddCompanyModal from 'modals/AddModals/AddCompanyModal';
import AddLocationModal from 'modals/AddModals/AddLocationModal';
import AddManifestModal from 'modals/AddModals/AddManifestModal';
import AddSourceModal from 'modals/AddModals/AddSourceModal';
import AddUserModal from 'modals/AddModals/AddUserModal';

const Header = () => {
    const { navigateTo } = useComponents();
    const { clearAllInputs } = useInputs();
    const { setModal } = useModal();
    const { header, breadcrumbs } = useProfile();
    const { changeTableTotals } = useTables();
    const { hasRank } = useUtils();
    const [ headerLabel, setHeaderLabel ] = useState();
    const [ pageButton, setPageButton ] = useState();
    const [ links, setLinks ] = useState();
    const currentHeader = useRef();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        createAddButton();
    }, [navigate])

    useEffect(() => {
        if (currentHeader.current === header || header === '') {return}
        setHeaderLabel(header);
    }, [header])

    useEffect(() => {
        changeTableTotals(null);
    }, [location])

    useEffect(() => {
        if (!breadcrumbs) { return }
        buildBreadcrumbs(breadcrumbs);
    }, [breadcrumbs])

    const createAddButton = () => {
        const url = location.pathname;
        let page = null;
        let permission;
        if (!url) {return}
        if (url.includes('companies') && !url.includes('user')) {
            permission = hasRank('superuser');
            page = t.addCompany;
        } else if (url.includes('locations')) {
            permission = hasRank('owner');
            page = t.addLocation;
        } else if (url.includes('devices') && !url.includes('batch-assignment')) {
            permission = hasRank('owner');
            page = t.batchAssignment;
        } else if (url.includes('users') && !url.includes('company') && !url.includes('permission')) {
            permission = hasRank('owner');
            page = t.addUser;
        } else if (url.includes('manifests')) {
            permission = hasRank('admin');
            page = t.addManifest;
        } else if (url.includes('sources')) {
            permission = hasRank('admin');
            page = t.addSource;
        } else {
            page = null;
        }
        permission ? setPageButton(page) : setPageButton(null);
    }

    const onAddClick = () => {
        clearAllInputs();
        let route = ''
        switch (pageButton) {
            case t.addCompany:
                setModal(<AddCompanyModal/>);
                break;
            case t.addLocation:
                setModal(<AddLocationModal/>);
                break;
            case t.addUser:
                setModal(<AddUserModal/>);
                break;
            case t.batchAssignment:
                route = '/devices/batch-assignment';
                break;
            case t.addManifest:
                setModal(<AddManifestModal/>);
                break;
            case t.addSource:
                setModal(<AddSourceModal/>);
                break;
            default:
                route = null;
        }
        if (!route) {
            return
        } else {
            navigateTo(route);
        }
    }

    const buildBreadcrumbs = (crumbs) => {
        const items = [];
        const length = crumbs.length;
        crumbs.forEach((crumb, i) => {
            items.push(<a data-link={crumb.url} href={crumb.url} key={crumb.url + i} className='header-link' onClick={(event) => {onLinkClick(event)}}>{crumb.label}</a>)
            i+1 !== length && items.push(<div key={'divider' + i} className='header-links-divider'>/</div>)
        })
        setLinks(items)
    }

    const onLinkClick = (event) => {
        event.preventDefault();
        navigateTo(event.currentTarget.dataset.link);
    }

    return (!location.pathname.includes('login') &&
        !location.pathname.includes('forgot-password') &&
        !location.pathname.includes('reset-password') &&
        <div id='header' className='header'>
            <div className='header-label-container'>
                <div className='header-label'>{headerLabel}</div>
                {((location?.pathname?.includes('/location/') || location?.pathname?.includes('/device/')) && !location?.pathname?.includes('batch-assignment')) && <div className='header-links-container'>
                    {links}
                </div>}
            </div>
            {pageButton ? <Button classes='header-add-button' onClick={()=>{onAddClick()}}>{pageButton !== t.batchAssignment ? '+ ' : ''}{pageButton}</Button>: ''}
        </div>
    )
}

export default Header;
