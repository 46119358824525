import { text as t } from 'shared/text';

export const devicesPOSLayout = {
    id: 'devices-pos',

    defaultSort:  {
        prop: 'id',
        desc: false,
    },

    tableHeaders: {
        checkbox: '',
        id: t.deviceId,
        machineSerialNumber: t.serialNumber,
        locationName: t.locationName,
        isOnline: t.online
    },

    sizes: {
        checkbox: 'icon',
        id: 'mid',
        machineSerialNumber: 'mid',
        locationName: 'mid',
        isOnline: 'small',
    },

    layouts: {
        id: 'text-left',
        machineSerialNumber: 'text-left',
        locationName: 'text-left',
    },

    navigation: {
        id: {
            root: 'device',
            label: 'id',
            value: 'id',
        },
        locationName: {
            root: 'location',
            label: 'locationName',
            value: 'locationId'
        }
    },

    hideSortArrows: {
        checkbox: true,
    },

    formats: {
        isOnline: 'indicator'
    },

    contextMenu: [
        'reboot',
        'pauseService',
        'startService',
    ]
}
