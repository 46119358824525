import './deleteAccounts.scss';
import { useEffect, useRef, useState } from 'react';
import { componentText } from 'components/shared/componentData';
import { toPin, deepCopy } from 'components/shared/componentUtils';
import { text as t, capText as c } from 'shared/text';
import useInputs from 'components/hooks/Inputs/useInputs';
import useModal from 'components/hooks/Modal/useModal';
import useUtils from 'hooks/useUtils';
import Page from 'OldComponents/Page/Page';
import Button from 'components/elements/Button/Button';
import Card from 'OldComponents/Cards/Card/Card';
import Input from 'components/elements/Input/Input';
import DeleteAccountsModal from 'modals/FlowModals/DeleteAccountsModal/DeleteAccountsModal';
const id = 'delete-account-phone';

const DeleteAccounts = () => {
    const { inputValues, clearInput, updateInputErrors } = useInputs();
    const { setModal } = useModal();
    const { callAPI, updateHeaderMessage } = useUtils();
    const [ accounts, setAccounts ] = useState();
    const [ selectedPins, setSelectedPins ] = useState([]);
    const runOnce = useRef(false);

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
        clearInput(id);
    }, []);

    const onSearch = (event, setLoading) => {
        setSelectedPins([])
        const phone = inputValues?.[id];
        if (phone.length < 10) {
            updateInputErrors(id, componentText.errorPhone);
        } else {
            setLoading(true);
            callAPI('accountSearchByPhone', accountSearchByPhoneCallback, {phoneNumber: phone}, '', setLoading)
        }
    }   

    const accountSearchByPhoneCallback = (data, setLoading) => {
        setLoading(false);
        if (data.isSuccessful) {
            setAccounts(data?.matches ? data.matches : []);
        } else {
            updateHeaderMessage(data);
        }
    }

    const onSelect = (pin) => {
        const newAccounts = deepCopy(selectedPins);
        if (newAccounts.includes(pin)) {
            const filtered = newAccounts.filter(item => item !== pin);
            setSelectedPins(filtered);
        } else {
            newAccounts.push(pin);
            setSelectedPins(newAccounts);
        }
    }

    const onSubmit = () => {
        setModal(<DeleteAccountsModal pins={selectedPins} phone={inputValues?.[id]} callback={setAccounts}/>)
    }

    const onCancel = () => {
        clearInput(id);
        document.getElementById(id).click();
    }  

    const buildAccounts = () => {
        if (!accounts) { return }
            const length = accounts.length;
        if (length === 0) {
            return 
        } else {
            return 
        }
    }

    return (
        <Page 
            page={t.deleteAccounts}
            contentClasses='grid'
        >
            <Card hideLabel={true} classes='half'>
                <Input  classes='three-quarters' id={id} startFocused={true} type='phone'
                        label={t.enterPhoneNumber}/>
                <Button 
                    classes='delete-account-search-button quarter green ' 
                    type='submit' 
                    onClick={(event, setLoading) => {onSearch(event, setLoading)}}>{t.search}</Button>
            </Card>
            {accounts && <Card label='Attached Accounts' classes='two-thirds'>
                <div className='delete-accounts-column-header quarter'>{t.company}</div>
                <div className='delete-accounts-column-header quarter'>{t.location}</div>
                <div className='delete-accounts-column-header third'>{c.pin}</div>
                {accounts?.length === 0 ? <div className='delete-accounts-no-results full'>{t.noResults}</div>
                :<div className='delete-accounts-container full'>
                    {accounts.map((account, i) => {
                        const pin = account.pin;
                        const id = 'delete-accounts-user-' + pin;
                        return (<div id={id} key={i} className={`delete-accounts-user-line grid ${selectedPins.includes(pin) ? 'delete-account-selected' : ''}`}>
                            <div className='delete-accounts-user-cell company quarter'>{account.company}</div>
                            <div className='delete-accounts-user-cell location quarter'>{account.location}</div>
                            <div className='delete-accounts-user-cell location quarter'>{toPin(account?.pin)}</div>
                            <div className='delete-accounts-select-button-container quarter'>
                                <Button 
                                    classes={`delete-accounts-select-button ${selectedPins.includes(pin) ? 'orange' : 'blue'}`}
                                    onClick={()=>{onSelect(pin)}}
                                >{selectedPins.includes(pin) ? t.deselect : t.select}</Button>
                            </div>
                        </div>)
                    })}
                </div>}
                {buildAccounts()}
                {accounts?.length > 0 && <Button 
                    classes='third red last' 
                    type='submit' 
                    disabled={selectedPins.length < 1} 
                    onClick={onSubmit}
                >{t.deleteAccounts}</Button>}
            </Card>}
        </Page>
    )
}

export default DeleteAccounts;