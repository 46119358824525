import './button.scss';
import { useEffect, useState } from 'react';
import Icon from '../Icon/Icon';

const Button = ({id, classes, type, onClick, icon, children, loading, prop, disabled}) => {
    const [ showSpinner, setShowSpinner ] = useState (false);
    const isTouchScreen = classes?.includes('keyboard') || classes?.includes('keypad') ? true : false;

    useEffect(()=>{
        setShowSpinner(loading);
    }, [loading])

    const onButtonClick = (event) => {
        event.stopPropagation();
        if (!onClick || disabled || showSpinner) {return};
        if (type === 'submit') {
            onClick(event, setShowSpinner);
        } else {
            onClick(event);
        }
    }

    return (
        <button 
            id={id}
            className={`button ${classes ? classes: ''} ${disabled ? 'disabled' : ''}`} 
            data-touchscreen={isTouchScreen}
            prop={prop}
            disabled={disabled || loading || showSpinner}
            onClick={(event)=>{onButtonClick(event)}}
        >
            {icon && <span className='button-icon-container'>
                <Icon icon={icon}/>
            </span>}
            <div className='button-text' data-touchscreen={isTouchScreen}>
                {showSpinner ? <span className='spinner'/> : children}
            </div>
        </button>
    )
}

export default Button;