import './sourceDetails.scss';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { sourceDetailsLayout as layout } from 'layouts/Sources/SourceDetailsLayout';
import { updatePageState } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import useInputs from 'components/hooks/Inputs/useInputs';
import useProfile from 'hooks/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/elements/Button/Button';
import Card from 'OldComponents/Cards/Card/Card';
import Dropdown from 'components/elements/Dropdown/Dropdown';
import Input from 'components/elements/Input/Input';
import Page from 'OldComponents/Page/Page';

const SourceDetails = ({newSource, newSubmit, newCancel}) => {
    const { id }  = useParams();
    const { updateInputData, changeInputErrors, 
            validateForm } = useInputs();
    const { setHeader } = useProfile();
    const { callAPI, hasRank, updateHeaderMessage } = useUtils();
    const [ pageData, setPageData ] = useState();
    const [ deviceTypes, setDeviceTypes ] = useState();
    const [ environments, setEnvironments ] = useState();
    const [ sourceTypes, setSourceTypes ] = useState();
    const [ masked, setMasked ] = useState();
    const runOnce = useRef(false);
    const setOnce = useRef(false);
    const permitted = hasRank('admin');

    useEffect(() => {
        if (runOnce.current) {return};
        runOnce.current = true;
        const params = newSource ? {updateSourceId: -1} : {updateSourceId: id};
        callAPI('sourceDetails', sourceDetailsCallback, params, '', params);
        callAPI('deviceTypes', deviceTypesCallback);
        callAPI('environments', environmentsCallback);
        callAPI('sourceTypes', sourceTypesCallback);
    }, []);

    useEffect(() => {
        if (runOnce.current && pageData?.id === id) {return};
        if (pageData && deviceTypes && environments && sourceTypes && !setOnce.current) {
            setOnce.current = true;
            const dropdownList = {
                terminalTypeId: {list: 0},
                environmentNameId: {list: 1},
                updateSourceType: {list: 2, nullable: t.none}
            }
            if (newSource) {
                pageData.id = -1;
            }
            const update = updateInputData(pageData, dropdownList, [deviceTypes, environments, sourceTypes]);
            update && setMasked(false);
        }
    }, [pageData, deviceTypes, environments, sourceTypes])

    const sourceDetailsCallback = (data, props, accessToken) => {
        if (data?.isSuccessful) {
            const details = data?.tableData?.[0];
            setPageData(details);
            !newSource && setHeader(details?.name ? details.name: '');
        } else {
            setPageData({});
        }
    }

    const deviceTypesCallback = (data) => {
        setDeviceTypes(data?.kioskTypes ? data.kioskTypes : []);
    };

    const environmentsCallback = (data) => {
        setEnvironments(data?.tableData ? data.tableData: []);
    }

    const sourceTypesCallback = (data) => {
        setSourceTypes(data?.tableData ? data.tableData : []);
    }

    const onSubmit = (event, setLoading) => {
        const errorsList = validateForm(layout.validators);
        const params = { record: pageData };
        const props = {event: event, setLoading: setLoading}
        if (newSource) {params.record.id = 0};
        if (errorsList) {
            updateHeaderMessage('formErrors', props);
            changeInputErrors(errorsList);
        } else {
            setLoading(true);
            callAPI('sourceUpdate', sourceUpdateCallback, params, '', props); 
        }
    }

    const sourceUpdateCallback = (data, props) => {
        newSource ? newSubmit(data) : updateHeaderMessage(data, props);
    }

    const onInputUpdate = (data) => {
        setPageData(updatePageState(data, pageData));
    }

    const onCancel = () => {
        newCancel();
    };

    return (
        <Page contentClasses='grid'>
            <Card classes='full' hideLabel={true}>
                <Input classes='half display-only' line='id' masked={masked}
                    label={t.id} noPermission={!permitted} onClick={onInputUpdate}/>
                <Input classes='half' line='name' masked={masked}
                    label={t.name} noPermission={!permitted} onClick={onInputUpdate}/>
                <Dropdown id='updateSourceType' classes='half' data={sourceTypes} masked={masked} nullable={t.none}
                    label={t.sourceType} noPermission={!permitted} onClick={onInputUpdate}/>
                <Input classes='half' line='url' masked={masked} maxLength={'none'} hideCover={true}
                    label={t.url} data={pageData} noPermission={!permitted} onClick={onInputUpdate}/>
                <Input classes='half' line='filename' masked={masked}                 
                    label={t.fileName} noPermission={!permitted} onClick={onInputUpdate} maxLength={100}/>
                <Dropdown id='environmentNameId' classes='half' data={environments} masked={masked}
                    label={t.environment} noPermission={!permitted} onClick={onInputUpdate}/>
                <Dropdown id='terminalTypeId' classes='half' data={deviceTypes} masked={masked}
                    label={t.deviceType} noPermission={!permitted} onClick={onInputUpdate}/>
            </Card>
            {newSource && <div className='full modal-buttons-container grid'>
                <Button 
                    classes='red quarter'
                    onClick={onCancel}
                    >{t.cancel}
                </Button>
                <Button 
                    classes='green quarter last' 
                    type='submit' 
                    onClick={(event, setLoading)=>onSubmit(event, setLoading)}
                >{t.submit}</Button>
            </div>}
            {!newSource && <Button 
                classes='green quarter last' 
                type='submit' 
                onClick={(event, setLoading)=>onSubmit(event, setLoading)}
            >{t.submit}</Button>}
        </Page>
    )
}

export default SourceDetails;
