import './companyPerformanceReports.scss';
import { useEffect, useState } from 'react';
import { companyPerformanceReportsLayout as layout } from 'layouts/Reports/CompanyPerformanceReportsLayout';
import { getDefaultDateRange, getAPIDates } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import useInputs from 'components/hooks/Inputs/useInputs';
import useTables from 'components/elements/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import DateRangePicker from 'components/elements/DateRangePicker/DateRangePicker';
import Dropdown from 'components/elements/Dropdown/Dropdown';
import TableListed from 'components/elements/Tables/TableListed';
const tableId = 'companyPerformanceReports';
const companyId = tableId + '-companyId';

const CompanyPerformanceReports = () => {
    const { updateDropdown } = useInputs();
    const { updateTable, updateDropdowns, getURLParams } = useTables();
    const { callAPI } = useUtils();
    const [ companies, setCompanies ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ tableData, setTableData ] = useState();
    const [ masked, setMasked ] = useState([true]);

    useEffect(() => {
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        getReportData(range);
        callAPI('companies', companiesCallback);
    }, [])

    useEffect(() => {
        const urlParams = getURLParams(tableId);
        if (urlParams.dropdowns) {
            const sorts = urlParams.dropdowns?.[companyId];
            sorts && updateDropdown(companyId, sorts.value, sorts.label);
        }
    }, [companies])

    const getReportData = (range) => {
        setMasked([true]);
        updateTable('dates', tableId, range);
        const params = getAPIDates(range);
        callAPI('report-CompanyPerformance', companyReportCallback, params);
    }

    const companyReportCallback = (data) => {
        setTableData(data?.data ? data.data : []);
        setMasked([false]);
    }

    const companiesCallback = (data) =>{
        setCompanies(data?.companyList ? data.companyList : []);
    }

    const onCompanySelect = (data) => {
        data.searchData = {
            dropdownProp: 'label',
            recordProp: 'companyName',
        }
        updateDropdowns(tableId, companyId, data);
    }

    const onDateRangePick = (range) => {
        getReportData(range);
    }

    return (<TableListed
        id={tableId}
        data={tableData}
        layout={layout}
        masked={masked?.[0]}
        searchBarContent={<>
            <Dropdown
                id={companyId}
                classes='quarter'
                label={t.companyName}
                hideErrors={true}
                data={companies}
                nullable={t.all}
                masked={masked?.[0]}
                callback={onCompanySelect}
            />
            <DateRangePicker
                id='companyReport-dateRange'
                classes='third'
                label={t.dateRange}
                defaultVal={defaultDates}
                disabled={masked?.[0]}
                callback={onDateRangePick}
            />
        </>}
    />)
}

export default CompanyPerformanceReports;
