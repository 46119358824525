import './error.scss';
import { text as t } from 'shared/text';
import Page from 'OldComponents/Page/Page';

const Error = () => {

    return (
        <Page>
            <div className='error-message'>{t.pageNotFound}</div>
        </Page>
    )
}

export default Error;