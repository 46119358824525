import { useEffect, useState } from 'react';
import Icon from 'components/elements/Icon/Icon';

const Checkbox = ({ id, classes, 
                    label, hideLabel, 
                    type, disabled, 
                    defaultVal, value,
                    noPermission, data,
                    callback}) => {
    const [ checked, setChecked ] = useState(false);

    useEffect(()=>{
        setChecked(defaultVal);
    }, [defaultVal])

    useEffect(()=>{
        if (type==='radio') {
            data !== id ? setChecked(false) : setChecked(true);
        }
    }, [data]);

    const onCheck = () =>{
        if (disabled || noPermission) {return}
        if (type !== 'manual') {
            setChecked(!checked);
        }
        if (!callback) {return};
        callback({
            id: id,
            value: (type === 'radio' && !checked) ? value : !checked
        })
    }

    return (
        <div className= {`checkbox-container ${classes ? classes : ''} ${hideLabel ? 'hide-label' : ''} ${noPermission ? 'display' : ''}`}>
            {label ? <div className='checkbox-label'>{label}</div> : null}
            <div className='checkmark-container'>
                <span
                    className={`checkmark ${type === 'radio' ? type : ''} ${disabled ? 'disabled': ''} ${checked ? 'checked' : ''}`}
                    tabIndex='-1'
                    onClick={()=>{onCheck()}}
                >
                    {Icon('check', 'checkmark-check')}
                </span>
            </div>
        </div>
    )
}

export default Checkbox;
