import './userLocationsModal.scss';
import { useEffect, useState } from 'react';
import { text as t } from 'shared/text';
import useModal from 'components/hooks/Modal/useModal';
import useUtils from 'hooks/useUtils';
import Button from 'components/elements/Button/Button';
import Icon from 'components/elements/Icon/Icon';
import Modal from 'components/elements/Modal/Modal';

const UserLocationsModal = ({id, currentList, newList, callback}) => {
    const { removeModal } = useModal();
    const { callAPI } = useUtils();
    const [ oldList, setOldList ] = useState([]);
    const [ updatedList, setUpdatedList ] = useState([]);

    useEffect(() => {
        const cumulativeList = [...currentList, ...newList].reduce((acc, currentItem) => {
            if (!acc.some(item => item.id === currentItem.id)) {
                acc.push(currentItem);
            }
            return acc;
        }, []).sort((a, b) => a.id - b.id); 
    
        const firstArray = cumulativeList.map(item => {
            const foundItem = currentList.find(listItem => listItem.id === item.id);
            return foundItem ? foundItem : { id: item.id, name: '' };
        });
        setOldList(firstArray);
        const secondArray = cumulativeList.map(item => {
            const foundItem = newList.find(newListItem => newListItem.id === item.id);
            return foundItem ? foundItem : { id: item.id, name: '' };
        });
        setUpdatedList(secondArray);
    }, [])


    const onSubmit = (setLoading) => {
        setLoading(true);

        const params = {
            webPortalUserId: id,
            locationIds: newList.map(obj => obj.id)
        }
        callAPI('userLocationsSet', callback, params, '', setLoading)
    }


    const onCancel = () => {
        removeModal(false);
    }

    return (
        <Modal title={t.updateUserLocations}>
            <div className='grid'>
                <div className='user-locations-modal-label five-twelfths left'>{t.currentLocations}</div>
                <div className='user-locations-modal-label five-twelfths last'>{t.newLocations}</div>
                <div className='five-twelfths'>
                    {currentList.length === 0 ? (<div className='user-locations-modal-item filler left'>
                        <span>{t.none}</span>
                    </div>)
                    : oldList.map((location, i)=>{
                        return <div key={i} className='user-locations-modal-item left'>{location.name}</div>
                    })}
                </div>
                <div className='user-locations-modal-arrow-box sixth'>
                    <Icon icon='arrow-solid-right' classes='user-locations-modal-arrow'/>
                </div>
                <div className='five-twelfths'>
                    {newList.length === 0 ? (<div className='user-locations-modal-item filler'>
                        <span>{t.none}</span>
                    </div>)
                    : updatedList.map((location, i)=>{
                        return <div key={i} className='user-locations-modal-item'>{location.name}</div>
                    })}
                </div>
                <Button 
                    classes='green half' 
                    type='submit' 
                    onClick={(event, setLoading) => {onSubmit(setLoading)}}>{t.updateLocations}</Button>
                <Button classes='red half' onClick={onCancel}>{t.cancel}</Button>
            </div>
        </Modal>
    )
}

export default UserLocationsModal;