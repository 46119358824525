import './devices.scss';
import { useEffect, useRef, useState } from 'react';
import { devicesBingoLayout as layout } from 'layouts/Devices/DevicesTable/DevicesBingoLayout';
import { filterDevicesByValue, } from 'shared/utils';
import { text as t } from 'shared/text';
import useProfile from 'hooks/useProfile';
import useUtils from 'hooks/useUtils';
import Page from 'OldComponents/Page/Page';
import Table from 'OldComponents/Table/Table/Table';

const DevicesBingo = () => {
    const { devicesTabs } = useProfile();
    const { callAPI, hasRank, updateDevicesTabs } = useUtils();
    const [ tableData, setTableData ] = useState();
    const [ tableLoading, setTableLoading ] = useState([true]);
    const runOnce = useRef(false);

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
        callAPI('devices', devicesListCallback);
        updateDevicesTabs();
    }, [])

    const devicesListCallback = (data) => {
        setTableLoading([false]);
        const list = data?.list ? filterDevicesByValue(data.list, 7 ) : []; // 5 for Bingo
        setTableData(list)
    }

    return (
        <Page
            page={t.devices}
            subPage={t.bingo}
            tabs={devicesTabs}
        >
            <Table
                data={tableData}
                layout={layout}
                context={useProfile}
                loading={tableLoading}
                editable={hasRank('locationManager')}
            />
        </Page>
    )
}

export default DevicesBingo;
