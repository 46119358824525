import { createContext, useRef, useState } from 'react';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [ auth, setAuth ] = useState();
    const [ loggedIn, setLoggedIn ] = useState();
    const [ logoutTimer, setLogoutTimer ]  = useState();
    const originalCalls = useRef([]);

    return (
        <AuthContext.Provider value={{ 
            auth, setAuth,
            loggedIn, setLoggedIn,
            logoutTimer, setLogoutTimer,
            originalCalls
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
