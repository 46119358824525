import './dateRangePicker.scss';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDatePicker from 'react-datepicker';
import { useEffect, useRef, useState } from 'react';
import { isThen, isValid, 
         setToStartOfDay, setToEndOfDay } from '../../shared/componentUtils';
import { text as t } from 'shared/text';

const DateRangePicker = ({classes, hideLabel, label, defaultVal, disabled, callback}) => {
  const [ dateRange, setDateRange ] = useState([null, null]);
  const [ startDate, endDate ] = dateRange;
  const [ open, setOpen ] = useState(false); 
  const runOnce = useRef(false);
  
  const defaultSet = useRef(false);
  const firstPick = useRef(false);

  useEffect(()=>{
    if (isValid(defaultVal)) {
      if (runOnce.current) {return}
      defaultSet.current = true;
      runOnce.current = true;
      setDateRange(defaultVal);
    }
  }, [defaultVal])

  useEffect(()=>{
    if (dateRange[0] && dateRange[1]) {
      if (defaultSet.current) {
        defaultSet.current = false;
        return;
      }
      let start = dateRange[0];
      let end = dateRange[1]; 
      callback([start, end]);
      setOpen(false);
    }
  }, [dateRange])

  const onDateRangeSelect = (date) => {
    if (disabled) {return};
    let [start, end] = date;
    start = setToStartOfDay(date[0]);
    if (firstPick.current) {
      end = date[1] ? setToEndOfDay(date[1]) : start;
      firstPick.current = false;
    } else {
      firstPick.current = true;
      end = date[1];
    }
    setDateRange([start, end]);
  };

  const onToday = () => {
    const today = new Date();
    const start = setToStartOfDay(today);
    const end = setToEndOfDay(today);
    setDateRange([start, end]);
  }

  const onYesterday = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const start = setToStartOfDay(yesterday);
    const end = setToEndOfDay(yesterday);
    setDateRange([start, end]);
  }

  const onThisWeek = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const start = setToStartOfDay(new Date(today.setDate(today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)))); // Start from Monday of this week
    const end = setToEndOfDay(new Date()); // End at today
    setDateRange([start, end]);
  };
  
  const onLastWeek = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
  
    const lastMonday = new Date(today.setDate(today.getDate() - dayOfWeek - 6)); // Last week's Monday
    const lastSunday = new Date(today.setDate(lastMonday.getDate() + 6)); // Most recent Sunday
  
    const start = setToStartOfDay(lastMonday);
    const end = setToEndOfDay(lastSunday);
    setDateRange([start, end]);
  };
  
  const onMonthToDate = () => {
    const today = new Date();
    const start = setToStartOfDay(new Date(today.getFullYear(), today.getMonth(), 1));
    const end = setToEndOfDay(new Date());
    setDateRange([start, end]);
  };
  
  const onLastMonth = () => {
    const today = new Date();
    const start = setToStartOfDay(new Date(today.getFullYear(), today.getMonth() - 1, 1));
    const end = setToEndOfDay(new Date(today.getFullYear(), today.getMonth(), 0));
    setDateRange([start, end]);
  };

  const onQuarterToDate = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const quarterStartMonth = currentMonth - (currentMonth % 3); // Calculate the starting month of the current quarter
    const start = setToStartOfDay(new Date(today.getFullYear(), quarterStartMonth, 1));
    const end = setToEndOfDay(today); // End date is today
    setDateRange([start, end]);
  };

  const onLastQuarter = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const quarterStartMonth = currentMonth - (currentMonth % 3) - 3; // Calculate the starting month of the last quarter
    const start = setToStartOfDay(new Date(today.getFullYear(), quarterStartMonth, 1));
    const end = setToEndOfDay(new Date(today.getFullYear(), quarterStartMonth + 2, getDaysInMonth(today.getFullYear(), quarterStartMonth + 2)));
    setDateRange([start, end]);
  };

  const onYearToDate = () => {
    const today = new Date();
    const start = setToStartOfDay(new Date(today.getFullYear(), 0, 1)); // Start date is January 1st of the current year
    const end = setToEndOfDay(today); // End date is today
    setDateRange([start, end]);
  };
  
  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  }

  return (
    <div className={`date-range-picker ${isThen(classes)}`}  >
      <div className={`date-range-label ${hideLabel ? 'hide-label' : ''}`}>{label}</div>
      <ReactDatePicker
        classes='date-range-display'
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(date) => onDateRangeSelect(date)}
        dateFormat="MM/dd/yyyy"
        open={open} 
        onInputClick={() => setOpen(true)}
        onClickOutside={() => setOpen(false)} 
        onCalendarClose={() => setOpen(false)} 
      >
        <div className='date-range-picker-options-list'>
            <div className='date-range-option' onClick={onToday}>{t.today}</div>
            <div className='date-range-option' onClick={onYesterday}>{t.yesterday}</div>
            <div className='date-range-option' onClick={onThisWeek}>{t.thisWeek}</div>
            <div className='date-range-option' onClick={onLastWeek}>{t.lastWeek}</div>
            <div className='date-range-option' onClick={onMonthToDate}>{t.monthToDate}</div>
            <div className='date-range-option' onClick={onLastMonth}>{t.lastMonth}</div>
            <div className='date-range-option' onClick={onQuarterToDate}>{t.quarterToDate}</div>
            <div className='date-range-option' onClick={onLastQuarter}>{t.lastQuarter}</div>
            <div className='date-range-option' onClick={onYearToDate}>{t.yearToDate}</div>
        </div>
      </ReactDatePicker>
    </div>
  );
};

export default DateRangePicker;