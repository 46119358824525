import './multiCheckModal.scss';
import { useEffect, useRef, useState } from 'react';
import { text as t } from 'shared/text';
import useModal from 'components/hooks/Modal/useModal';
import useProfile from 'hooks/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/elements/Button/Button';
import Modal from 'components/elements/Modal/Modal';

const MultiCheckModal = ({pageId, prop, type, data}) => {
    const { setModal } = useModal();
    const { tableStates } = useProfile();
    const { callAPI } = useUtils();
    const [ selected, setSelected ] = useState();
    const [ running, setRunning ] = useState(false);
    const [ disableClose, setDisableClose ] = useState(true);
    const runOnce = useRef();
    const runList = [];

    useEffect(()=>{
        if (runOnce.current) {return}
        runOnce.current = true;
        const checks = tableStates[pageId].multiCheck;
        const length = checks.length;
        setSelected(checks);
        setTimeout(() => {
            for (let i=0; i<length; i++) {
                const id = checks[i];
                const params = {
                    terminalId: id,
                    terminalCommandType: type
                };
                if(data){
                    params['data'] = data;
                }
                const props = {
                    length: length,
                    id: id
                };
                callAPI('terminalPost', contextCallback, params, '', props )
            }
        }, 500);
    }, []);

    const contextCallback = (data, props) => {
        const id = props.id;
        const classList = document.getElementById(id + '-state-icon').classList;
        if (data.isSuccessful) {
            classList.add('success');
        } else {
            classList.add('fail');
        }
        runList.push(id);
        if(props.length === runList.length) {
            setRunning(false);
            setDisableClose(false);
        }
    }

    const onCloseClick = () => {
        setModal(false);
    }

    return (
        <Modal classes='multicheck-modal' title={t[prop] + ' ' + t.progress + ':'} contentClasses='grid'>
            <div className='list-container two-thirds centered'>
                {selected && selected.map((item, i) => {
                    return <div key={i} className='list-row'>
                        <div className='list-state-icon' id={`${item}-state-icon`} >
                            <span className='checkmark'></span>
                        </div>
                        <div className='list-label'>{item}</div>
                    </div>
                })}
            </div>
            <Button 
                classes='half centered red multicheck-close-button'
                disabled={disableClose} 
                type='submit'  
                onClick={onCloseClick}
                >{t.close}
            </Button>
        </Modal>
    )
}

export default MultiCheckModal;
