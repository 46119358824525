import './companies.scss';
import { useEffect, useRef, useState } from 'react';
import { companiesLayout as layout } from 'layouts/Companies/CompaniesLayout';
import useUtils from 'hooks/useUtils';
import useProfile from 'hooks/useProfile';
import Page from 'OldComponents/Page/Page';
import Table from 'OldComponents/Table/Table/Table';

const Companies = () => {
    const { refreshPage, setRefreshPage } = useProfile();
    const { callAPI } = useUtils();
    const [ companies, setCompanies ] = useState();
    const [ tableLoading, setTableLoading ] = useState([true]);
    const runOnce = useRef(false);
    
    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
        callAPI('companies', companiesCallback);
    }, [])

    useEffect(() => {
        refreshPage[0] && callAPI('companies', companiesCallback);
    }, [refreshPage])

    const companiesCallback = (data) =>{
        setCompanies(data?.companyList ? data?.companyList : []);
        setTableLoading([false]);
        setRefreshPage([false]);
    }

    return (
        <Page page={layout.page}>
            <Table 
                data={companies}
                layout={layout} 
                classes='full'
                context={useProfile}
                loading={tableLoading}
            />
        </Page>
    )
}

export default Companies;