import './tab.scss';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import useComponents from 'components/hooks/Components/useComponents';

const Tab = ({ data, selected }) => {
    const { id } = useParams();
    const { navigateTo } = useComponents();
    const [ open, setOpen ] = useState();
    const links = data.links && data.links.length > 0 ? data.links : null;
    const label = data.label;
    const path = data.path ? data.path.replace(':id', data.targetId ? data.targetId : id) : ''; // Ensure the path is dynamically replaced

    const onTabClick = (event) => {
        event.preventDefault(); // Prevent the default anchor behavior
        if (selected) {return}
        if (event.currentTarget.dataset.haslinks) {
            setOpen(true);
        } else {
            navigateTo(event.currentTarget.getAttribute('href'));
        }
    };

    if (links) {
        return (
            <div
                className={`tab-container`}
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
            >
                <div className={`tab ${selected ? 'selected' : ''}`} onClick={(event) => onTabClick(event)}>
                    <div className='tab-label'>{label}</div>
                </div>
                <div className={`tab-menu ${open ? 'tab-menu-open' : ''}`}>
                    {links.map((item, index) => (
                        <a
                            key={index}
                            className='tab-menu-item'
                            href={item.path.replace(':id', id)}
                            data-haslinks
                            onClick={(event) => {onTabClick(event)}}
                        >
                            <div className='tab-text'>{item.label}</div>
                        </a>
                    ))}
                </div>
            </div>
        );
    } else {
        return (
            <a
                className='tab-container'
                href={path}
                onClick={(event) => {onTabClick(event)}}
                data-path={path}
            >
                <div className={`tab ${selected ? 'selected' : ''}`}>
                    <div className='tab-label'>{label}</div>
                </div>
            </a>
        );
    }
}

export default Tab;
