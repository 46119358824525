import { createContext, useState } from 'react';

const ProfileContext = createContext({});

export const ProfileProvider = ({ children }) => {
    const [ size, setSize ] = useState('default');
    const [ menuOpen, setMenuOpen ] = useState(false);
    const [ tableStates, setTableStates ] = useState({});
    const [ refreshPage, setRefreshPage ] = useState([false]);
    const [ freeze, setFreeze ] = useState([false]);
    const [ username, setUsername ] = useState();
    const [ header, setHeader ] = useState();
    const [ tooltipData, setTooltipData ] = useState();
    const [ headerMessage, setHeaderMessage ] = useState('');
    const [ breadcrumbs, setBreadcrumbs ] = useState();
    const [ devicesTabs, setDevicesTabs ] = useState([]);
    const [ userTabs, setUserTabs ] = useState({tabs: []});
    const [ sources, setSources ] = useState();

    return (
        <ProfileContext.Provider value={{
            size, setSize, 
            menuOpen, setMenuOpen,
            tableStates, setTableStates,
            refreshPage, setRefreshPage,
            freeze, setFreeze,
            username, setUsername,
            header, setHeader,
            tooltipData, setTooltipData,
            headerMessage, setHeaderMessage,
            breadcrumbs, setBreadcrumbs,
            devicesTabs, setDevicesTabs,
            userTabs, setUserTabs,
            sources, setSources,
        }}>
            {children}
        </ProfileContext.Provider>
    )
}

export default ProfileContext;
