import './companyServices.scss';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { companyTabsList as tabs } from 'layouts/Companies/CompanyTabs';
import { deepCopy } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import useInputs from 'components/hooks/Inputs/useInputs';
import useProfile from 'hooks/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/elements/Button/Button';
import Card from 'OldComponents/Cards/Card/Card';
import Page from 'OldComponents/Page/Page';
import ServiceItem from 'OldComponents/ServiceItem/ServiceItem';

const CompanyServices = () => {
    const { id }  = useParams();
    const { inputValues, changeInputErrors, 
            reNestedData, updateNestedInputData,
            clearAllInputs } = useInputs();
    const { setHeader } = useProfile();
    const { callAPI, callAPIGet, 
            hasRank, updateHeaderMessage } = useUtils();
    const [ pageData, setPageData ] = useState();
    const [ services, setServices ] = useState();
    const [ masked, setMasked ] = useState([true]);
    const runOnce = useRef(false);
    const setOnce = useRef(false);

    useEffect(() => {
        if (runOnce.current && pageData?.id === id) {return};
        runOnce.current = true;
        callAPI('companyDetails', companyDetailsCallback, {id: id});
        callAPIGet('companyServicesGet', companyServicesGetCallback, {companyId: id});
        return () => {clearAllInputs()};
    }, []);

    useEffect(() => {
        changeInputErrors(null);
    }, [inputValues])
    
    useEffect(() => {
        if (services && pageData && !setOnce.current) {
            setOnce.current = true;
            const companyServices = pageData.serviceTypes;
            const newServices = deepCopy(services);
            companyServices.forEach((service) => {
                const type = getServiceType(service.serviceTypeEnum);
                newServices[type].present = true;
            })
            const update = updateNestedInputData(services);
            update && setMasked([false]);
        }
    }, [services, pageData])
    
    const companyDetailsCallback = (data) => {
        if (data?.isSuccessful) {
            const details = data?.companyDetailsData;
            setHeader(details.companyInfo?.companyName)
            setPageData(details);
        } else {
            setPageData({})
        }
        setMasked([false])
    }

    const companyServicesGetCallback = (data) => {
        if (data.isSuccessful) {
            delete data.errorMessage;
            delete data.httpStatusCode;
            delete data.isSuccessful;
            delete data.responseTypeEnum;
            setServices(data);
        }
    }

    const onSubmit = (event, setLoading) => {
        const errorsList = validateRates();
        const props = {event: event, setLoading: setLoading}
        if (errorsList) {
            updateHeaderMessage('formErrors', props);
            changeInputErrors(errorsList);
        } else {
            setLoading(true);
            changeInputErrors({})
            const params = reNestedData(deepCopy(services));
            params.companyId = id;
            callAPI('companyServicesSet', companyServicesSetCallback, params, '', props);
        }
    }

    const companyServicesSetCallback = (data, props) => {
        props.setLoading(false);
        updateHeaderMessage(data, props);
    }

    // Helper Functions
    const validateRates = () => {
        let errors;
        Object.keys(services).forEach((prop) => {
            const opId = prop + '-operatorRate';
            const locId = prop + '-locationRate';
            if (inputValues[prop + '-active'] && (parseInt(inputValues?.[opId]) + parseInt(inputValues?.[locId]) !== 100)) {
                if (!errors) { errors = {} };
                errors[opId] = t.errorShareRates;
                errors[locId] = t.errorShareRates;
            }
        })
        return errors;
    }

    const getServiceType = (val) => {
        switch (val) {
            case 2:
                return 'primePay'
            case 3:
                return 'sweepsKiosk'
            case 4:
                return 'bingoKiosk'
            case 5:
                return 'connectedGames'
            default:
                return t.unknown
        }
    }

    return (
        <Page
            subPage={t.services}
            tabs={tabs}
            contentClasses='grid company-details'
        >
            <Card classes={`full`} label={t.services}>
                {services && Object.entries(services).map(([prop], i) => (
                    <ServiceItem
                        key={i}
                        prop={prop}
                        page={'company'}
                        classes='third'
                        masked={masked?.[0]}
                    />
                ))}
            </Card>
            {hasRank('owner', true) && <Button
                classes={`green last quarter`}
                type='submit'
                onClick={(event, setLoading)=>onSubmit(event, setLoading)}
                >{t.submit}
            </Button>}
        </Page>
    )
}

export default CompanyServices;
